@import "~csp-common-ui/dist/styles/color.scss";
@import "~csp-common-ui/dist/styles/media-query.scss";
@import "~csp-common-ui/dist/styles/z-index.scss";

.notificaiton-container {
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 312px;
    z-index: $topHeaderWidgetsZIndex;

    .notification-container-inner {
        max-height: calc(80vh - 80px);
        min-height: 100px;

        .notification-header {
            box-shadow: 0px -0.5px 0px 0px #607D8B3B inset;

            .notification-fs {
                font-size: 18px;
            }
        }

        .alert-box {
            box-shadow: inset 0px 1px 0px #E5E5E5, inset -1px -1px 0px #E5E5E5;
            border: 1px solid $infoMain;
            border-radius: 4px;
            
            .alert-box-bg {
                background-color: $infoLightBg;
            }

            .alert-icon-button {
                align-self: baseline;
                
                .icon-close {
                    font-size: 28px;
                    color: $actionActive;
                    line-height: 1;
                }
            }
            
        }

        .notification-scrollbar {
            overflow-y: auto;
            overflow-x: hidden;
            &::-webkit-scrollbar {
                width: 11px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #C4C4C4;
            }

            .notification-details {
                background: $grey-50;
                padding: 0px 8px 0px 16px;
                display: flex;
                align-items: center;
                box-shadow: inset 0px -0.5px 0px rgba(96, 125, 139, 0.23);

                .group-heading {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                    padding-left: 4px;
                    color: $textPrimary;
                }

                .icon-flag {
                    color: $actionActive;
                }
            }

            .notification-item {
                padding: 16px;
                padding-left: 32px;
                box-shadow: inset 0px -0.5px 0px rgba(96, 125, 139, 0.23);

                .notification-link {
                    text-decoration: none;
                    border-bottom: 1px solid $textPrimary;
                    color: $textPrimary;
                }
            }
        }
    }
}

.notification-bell {
    .notification-bell-icon {
        background-color: $secondaryContrastText;
        border-radius: 8px;
        width: 44px;
        height: 44px;
        &:hover {
            background-color: $grey-100;
        }
        &.opened {
            background-color: $secondaryMain;
            .icon {
                    color: $secondaryContrastText;
                }
            }
        .icon {
            color: $secondaryTextDark;
        }

    }
        
}