@import "~csp-common-ui/dist/styles/color.scss";

 @media print {
        .pagebreak {
            break-after: always !important;
            page-break-after: always !important;
            page-break-inside: avoid !important;
        }
      }
.connote-container-dashboard {
    background-color: $secondaryContrastText;
    width: 842px;
    height: 603px;
    color: black;
    margin: 0 auto;

    @media print {
        transition: none !important;
        -webkit-print-color-adjust: exact;
        -webkit-transform: scale(1.33,1.31);
        position: relative;
        left: -148px;
        -webkit-transform-origin-y: top;
        -webkit-transform-origin-x: left;
    }
    @media screen and (max-height:600px) {
        transform:scale(0.9);
        transform-origin: left;
        margin: 0 auto;
    }

    @media screen and (max-width:840px) {
        transform-origin: left;
        transform:scale(0.95);
    }
    @media screen and (max-width:800px) {
        transform-origin: left;
        transform:scale(0.90);
    }
    @media screen and (max-width:700px) {
        transform:scale(0.8);
        transform-origin: left;
        margin: 0 auto;
    }
    @media screen and (max-width:600px) {
        transform:scale(0.75);
        transform-origin: left;
        margin: 0 auto;
    }
    @media screen and (max-width:560px) {
        transform:scale(0.63);
        margin: 0 auto;
    }
    @media screen and (max-width:480px) {
        transform:scale(0.58);
        margin: 0 auto;
    }
    @media screen and (max-width:440px) {
        transform:scale(0.50);
        margin: 0 auto;
    }
    @media screen and (max-width:400px) {
        transform:scale(0.47);
        margin: 0 auto;
    }

    @media screen and (max-width:360px) {
        transform:scale(0.45);
        margin: 0 auto;
    }
    
    h4{
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 143%;
    }
    .connote-title{
        display: flex;
        flex-direction: row;
        .qrcode-align{
            position: relative;
            bottom: 24px;
            height: 100%;
        }
        .ponumber-align{
            padding: 16px;
            position: relative;
            bottom: 16px;
            .po-flex-align{
                flex-wrap: wrap;               
            }
            .tag-component {
                .co-MuiChip-root {
                    position: fixed;
                }
            }           
        }
        .details-align{
            position: relative;
            bottom: 24px;
        }
        .details-column{
            padding-left:16px;
            h2{
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 148%;
            }
            .display-data{
                display: flex;
                padding-bottom: 8px;
                width: 265px;
                .details-titles{
                    padding-right: 8px;
                    h3{
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 10px;
                        line-height: 143%;
                        white-space: nowrap;
                    }
                    .table-row {
                        vertical-align: top;
                        .table-data {
                            h4 {
                                word-break: break-all;
                            }
                        }
                    }
            }
            .details-values{
                display: inline-block;
                flex-direction: row;
                justify-content:left;
                white-space: pre;
            }
        }
        .terms-chip{
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 9px;
            line-height: 137%;
            text-align: right;
            background: #ECEFF1;
            border-radius: 4px;
            i{
               height: 12px;
               width: 12px;
            }
        }    
    }
    h2{
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 142.86%;
    }
        .pickup-column{
            width: 185px;
            display: flex;
            flex-direction: column;
            padding-top: 0px;
            padding-left: 16px;
            i{
                padding-right: 8px;
            }
            
            div{
                padding-top: 8px;
                align-items: center;
                display: flex; 
            }
        }

        .delivery-column{
            width: 185px;
            display: flex;
            flex-direction: column;
            padding-top: 0px;
            padding-left: 16px;

            i{
                padding-right: 8px;
            }
            div{
                align-items: center;
                padding-top: 8px;
                display: flex; 
            }
        }
    }
    .table-align{
        padding: 0px;
        position: relative;
        bottom: 10px;
        .table{
            padding-top: 24px;
            height: 190px;
            .title-cell{
                padding:12px;
                padding-left:0;
                padding-right:0;
                border-bottom:1px solid #3D4548;
                width:88px;
            }
            .table-cell{
                padding:16px;
                padding-left:0;
                padding-right:0px;
                width:99px;
            }
            h3{
                font-family: Inter;
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 143%;
            }
            h4{
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 143%;
            }

        }
        .details{
            display: flex;
            flex-direction: column;
            h5{
                padding-left: 4px;
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 9px;
                line-height: 143%;
            }
            .top-row{
                display: flex;
                flex-direction: row;
                .top-row-first{
                    width:198px; 
                    height:40px; 
                    border:1px solid lightgrey; 
                    border-bottom:0px;
                    h4{
                        padding-top: 4px;
                    }
                }
                .top-row-box-driver{
                    width:198px;
                    height:40px;
                    border-right:1px solid lightgrey; 
                    border-top:1px solid lightgrey;
                }
                .top-row-box{
                    width:198px;
                    height:40px;
                    border-right:1px solid lightgrey; 
                    border-top:1px solid lightgrey;
                }
                
            }
            .bottom-row{
                display: flex;
                flex-direction: row;
                .bottom-row-first{
                    width:132px;
                    height:48px;
                    border:1px solid lightgrey;
                }
                .bottom-row-box{
                    width:200px;
                    height:48px;
                    border:1px solid lightgrey; 
                    border-left:0;
                }
                .bottom-row-sig{
                    width:177px;
                    height:48px;
                    border:1px solid lightgrey; 
                    border-left:0;
                    border-bottom:1.5px solid black;
                    span {
                        font-size: 10px;
                        position: relative;
                        top: 16px;
                    }
                }
                
            }
        }

        .footer{
            display: flex;
            flex-direction: row;
            padding-top:16px;
            padding-bottom: 14.5px;
            align-items: center;
            justify-content: space-between;
            img {
                &.primary-connect-logo {
                    height: 28px;
                }
            }
            h5{
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 9px;
                text-align: right;
                line-height: 143%;
            }
        }
    }
}